.text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 200px;
}

.error {
  cursor: pointer;
}
.error:hover {
  color: red;
}

@font-face {
  font-family: 'Monotype Gil Sans Nova Bold';
  src: url('./assets/fonts/Monotype-Gill_Sans_Nova_Cn_Bold.otf');
}

@font-face {
  font-family: 'Monotype Gil Sans Nova SemiBold';
  src: url('./assets/fonts/Monotype-Gill_Sans_Nova_Cn_SemiBold.otf');
}

@font-face {
  font-family: 'Monotype Gil Sans Nova Light';
  src: url('./assets/fonts/Monotype-Gill_Sans_Nova_Light.otf');
}

@font-face {
  font-family: 'Monotype Gil Sans Nova Inline ExtraLt';
  src: url('./assets/fonts/Monotype-Gill_Sans_Nova_Inline_ExtraLt.otf');
}

@font-face {
  font-family: 'Monotype Gil Sans Nova Medium';
  src: url('./assets/fonts/Monotype-Gill_Sans_Nova_Medium.otf');
}

@font-face {
  font-family: 'OpenSans Light';
  src: url('./assets/fonts/OpenSans-Light.ttf');
}

@font-face {
  font-family: 'OpenSans Italic';
  src: url('./assets/fonts/OpenSans-Italic.ttf');
}

@font-face {
  font-family: 'OpenSans';
  src: url('./assets/fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'OpenSans SemiBold';
  src: url('./assets/fonts/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'OpenSans Bold';
  src: url('./assets/fonts/OpenSans-Bold.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Monotype Gil Sans Nova Light';
}

h1 {
  font-family: 'Monotype Gil Sans Nova Bold';
}

:root {
  --white: #fff;
  --background: #f2f3f5;
  --text: #666666;
  --title: #2e384d;
  --red: #e83f5b;
  --red-hover: #e03450;
  --green: #4cd62b;
  --blue: #5965e0;
  --dark-blue: #4953b8;
}

@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}

body {
  background: var(--background);
  color: var(--text);
}

button {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

button.btn-primary {
  background-color: var(--red);
  border-color: var(--red);
}

button.btn-primary:hover {
  background-color: var(--red-hover);
  border-color: var(--red-hover);
}

button.btn-primary:active,
button.btn-primary:focus {
  background-color: var(--red-hover) !important;
  border-color: var(--red-hover) !important;
}

.form-container {
  max-width: 800px;
  margin: 0 auto;
}

.card {
  text-align: center;
}

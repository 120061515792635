.container_order_detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container_order_detail_title {
  font-family: 'Monotype Gil Sans Nova Bold', sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.container_order_detail-header-description {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
  white-space: nowrap;
  text-align: center;
}

.container_order_detail_dados-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container_order_detail_dados_container-cliente-subtitle {
  font-family: 'Monotype Gil Sans Nova Bold', sans-serif;
  font-size: 20px;
}

.container_order_detail_dados-cliente-description {
  font-size: 20px;
}
